import { makeStyles } from '@fluentui/react-components';
import React, { useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeExternalLinks from 'rehype-external-links';
import type { Pluggable } from 'unified';
import 'katex/dist/katex.min.css';

const rehypeKatexPlugin: Pluggable = [
    rehypeKatex,
    { output: 'html', throwOnError: false, strict: 'ignore' },
] as Pluggable;

const useClasses = makeStyles({
    content: {
        wordBreak: 'break-word',
        '& img': {
            maxWidth: '300px',
        },
        '& a': {
            fontWeight: 'bold',
        },
    },
    citationLink: {
        cursor: 'pointer',
        color: 'blue',
    },
});

interface ChatHistoryTextContentProps {
    message: string;
    explainData?: Array<{ response_part: string; source_file_name: string; page: number; source_text: string }>;
    messageId?: string;
    setProcessedContent?: (processedContent: { contentHtml: string; contentText: string }) => void;
    setCitations?: (
        citations: Array<{
            citationTag: string;
            source: string;
            source_text: string;
            response_part: string;
            page: number;
        }>,
    ) => void;
}

export const ChatHistoryTextContent: React.FC<ChatHistoryTextContentProps> = ({
    message,
    explainData,
    setProcessedContent,
    setCitations,
    messageId = '',
}) => {
    const classes = useClasses();
    const contentRef = useRef<HTMLDivElement>(null);

    let citationIndex = 1;
    const citations: Array<{
        citationTag: string;
        source: string;
        source_text: string;
        response_part: string;
        page: number;
    }> = [];
    let processedMessage = message;

    const citationMap = new Map<string, Array<{ citationTag: number; citationId: string }>>();

    Array.isArray(explainData) &&
        explainData?.forEach((item) => {
            const { response_part, source_file_name, page, source_text } = item;

            const citationTagNumber = citationIndex;
            const citationTag = String(citationTagNumber);
            const citationId = `citation-${messageId}-${String(citationTagNumber)}`;

            if (!citationMap.has(response_part)) {
                citationMap.set(response_part, []);
            }

            citationMap.get(response_part)?.push({ citationTag: citationTagNumber, citationId });

            citations.push({
                source_text,
                response_part,
                citationTag,
                page,
                source: source_file_name,
            });

            citationIndex++;
        });

    citationMap.forEach((citationEntries, response_part) => {
        citationEntries.sort((a, b) => a.citationTag - b.citationTag);

        const citationLinks = citationEntries
            .map(({ citationTag, citationId }) => `[[${String(citationTag)}]](#${citationId})`)
            .join(' ');

        processedMessage = processedMessage.replace(response_part, `${response_part} ${citationLinks}`);
    });

    React.useEffect(() => {
        if (contentRef.current && setProcessedContent) {
            const contentHtml = contentRef.current.innerHTML;
            const contentText = contentRef.current.textContent ?? '';
            setProcessedContent({ contentHtml, contentText });
        }

        if (setCitations) {
            setCitations(citations);
        }
    }, [message, processedMessage, explainData]);

    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        const href = event.currentTarget.getAttribute('href');

        if (href?.startsWith(`#citation-${messageId}-`)) {
            const citationElement = document.getElementById(href.substring(1));
            citationElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const preprocessMath = (text: string): string => {
        return text.replace(/\\\[/g, '$$').replace(/\\\]/g, '$$').replace(/\\\(/g, '$$').replace(/\\\)/g, '$$');
    };

    return (
        <div ref={contentRef} className={classes.content}>
            <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[
                    [rehypeExternalLinks, { target: '_blank', rel: 'noopener noreferrer' }],
                    rehypeKatexPlugin,
                ]}
                transformLinkUri={(value: string) => value}
                key={message}
                components={{
                    a: ({ href, children }) => {
                        if (href?.startsWith(`#citation-${messageId}-`)) {
                            return (
                                <a href={href} className={classes.citationLink} onClick={handleLinkClick}>
                                    {children}
                                </a>
                            );
                        }
                        return (
                            <a href={href} target="_blank" rel="noopener noreferrer">
                                {children}
                            </a>
                        );
                    },
                }}
            >
                {preprocessMath(processedMessage)}
            </ReactMarkdown>
        </div>
    );
};
