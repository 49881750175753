import { IImagesChatMessage } from './imagesSlice';
import { IGalleryItems } from '../../../libs/services/ImagesService';

export interface IImagesMessage {
    prompt_uuid?: string;
    images_uuid?: string;
    user?: string;
    image_url: string;
    thumbnail_url: string;
    gallery_thumbnail_url: string;
    download_url: string;
    favorite?: boolean;
    feedback?: string | null;
    date?: string;
}

export interface ImagesState {
    conversation: {
        messages: IImagesChatMessage[];
        botResponseStatus: string;
        isMessageSuccess: boolean;
    };
    gallery: {
        items: IGalleryItems[];
        total: number | null;
        page: number;
        size: number | null;
        pages: number | null;
    };
    prompt: string;
    enhancingPrompt: string;
    options: {
        style: string[];
        angle: string[];
        others: Record<string, string>;
    };
    model_options: {
        aspect_ratio: string;
        negative_prompt: string;
        model: string;
        number_of_results: number;
    };
}

export const initialState: ImagesState = {
    conversation: {
        messages: [],
        botResponseStatus: '',
        isMessageSuccess: false,
    },
    gallery: {
        items: [],
        total: null,
        page: 0,
        size: null,
        pages: null,
    },
    prompt: '',
    enhancingPrompt: '',
    options: {
        style: [],
        angle: [],
        others: {},
    },
    model_options: {
        aspect_ratio: '1:1',
        negative_prompt: '',
        model: 'imagen-3.0-generate-002',
        number_of_results: 4,
    },
};
