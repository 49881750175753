import { useDispatch, useSelector } from 'react-redux';
import { finishPopover } from '../redux/features/popoverSlice';
import { RootState, AppDispatch } from '../redux/app/store';
import PopoverWithArrow from './PopoverWithArrow';
import React, { useEffect, useState } from 'react';

const SixthPopover: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { popovers, lastPopoverFinished } = useSelector((state: RootState) => state.popover);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const rootClassName = popovers[5] ? 'wizard-shadow' : '';

    useEffect(() => {
        if (popovers[5]) {
            setAnchorEl(document.querySelector('#sixth-anchor') as HTMLElement);
        }
    }, [popovers[5]]);

    const handleFinish = () => {
        dispatch(finishPopover());
    };

    if (lastPopoverFinished) {
        return null;
    }

    return (
        <div id="shadow" className={rootClassName}>
            <PopoverWithArrow
                open={popovers[5]}
                anchorEl={anchorEl}
                slotProps={{
                    root: {
                        sx: {
                            zIndex: 10000,
                        },
                    },
                    paper: {
                        sx: {
                            top: '50%!important',
                            left: '50%!important',
                            transform: 'translate(-50%, -50%)!important',
                            overflow: 'visible',
                            borderRadius: '8px',
                        },
                    },
                }}
            >
                <div
                    style={{
                        padding: '24px 32px',
                        fontSize: '18px',
                        minWidth: '200px',
                        maxWidth: '350px',
                    }}
                >
                    <div
                        style={{
                            fontWeight: '700',
                            marginBottom: '8px',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <span>6/6</span>
                    </div>
                    <div>
                        Now, MaIA will also explain sources used to generate answers.
                        <br />
                        -Web sources
                        <br />
                        &
                        <br />- Extract of uploaded documents. Just click on “Add sources” below the first answer of
                        MaIA.
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end',
                            gap: '16px',
                            marginTop: '16px',
                        }}
                    >
                        <div
                            onClick={handleFinish}
                            style={{
                                borderRadius: '28px',
                                background: '#030F2B',
                                color: '#fff',
                                padding: '8px 32px',
                                textAlign: 'center',
                                fontWeight: '400',
                                fontSize: '12px',
                                cursor: 'pointer',
                                textTransform: 'uppercase',
                            }}
                        >
                            Finish
                        </div>
                    </div>
                </div>
            </PopoverWithArrow>
        </div>
    );
};

export default SixthPopover;
