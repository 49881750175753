import { useDispatch, useSelector } from 'react-redux';
import { nextPopover, skipPopovers } from '../redux/features/popoverSlice';
import PopoverWithArrow from './PopoverWithArrow';
import { RootState, AppDispatch } from '../redux/app/store';
import React from 'react';
import { setSelectedTab } from '../redux/features/app/appSlice';

const FirstPopover: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { popovers, lastPopoverFinished } = useSelector((state: RootState) => state.popover);
    const rootClassName = popovers[0] ? 'wizard-shadow' : '';

    const handleNext = () => {
        dispatch(setSelectedTab('promptLibrary'));
        dispatch(nextPopover({ current: 0 }));
    };

    const handleSkip = () => {
        dispatch(skipPopovers());
    };

    if (lastPopoverFinished) {
        return null;
    }

    return (
        <div id="shadow" className={rootClassName}>
            <PopoverWithArrow
                open={popovers[0]}
                transitionDuration={0}
                slotProps={{
                    root: {
                        sx: {
                            zIndex: 10000,
                        },
                    },
                    paper: {
                        sx: {
                            top: '50%!important',
                            left: '50%!important',
                            transform: 'translate(-50%, -50%)!important',
                            overflow: 'visible',
                            borderRadius: '8px',
                        },
                    },
                }}
            >
                <div
                    style={{
                        padding: '24px 32px',
                        fontSize: '18px',
                        minWidth: '200px',
                        maxWidth: '400px',
                    }}
                >
                    <div
                        style={{
                            fontWeight: '700',
                            marginBottom: '8px',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <span>1/6</span>
                    </div>
                    <div>
                        MaIA introduces new features
                        <br />
                        Let’s discover them!
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end',
                            gap: '16px',
                            marginTop: '16px',
                        }}
                    >
                        <div
                            onClick={handleSkip}
                            style={{
                                textDecoration: 'underline',
                                textUnderlineOffset: '7px',
                                textTransform: 'uppercase',
                                fontWeight: '400',
                                fontSize: '12px',
                                cursor: 'pointer',
                            }}
                        >
                            Skip the onboarding tour
                        </div>
                        <div
                            onClick={handleNext}
                            style={{
                                borderRadius: '28px',
                                background: '#030F2B',
                                color: '#fff',
                                padding: '8px 32px',
                                textAlign: 'center',
                                fontWeight: '400',
                                fontSize: '12px',
                                cursor: 'pointer',
                                textTransform: 'uppercase',
                            }}
                        >
                            Next
                        </div>
                    </div>
                </div>
            </PopoverWithArrow>
        </div>
    );
};

export default FirstPopover;
