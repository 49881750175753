import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, IPromptsState } from './PromptsState';

export interface IPrompts {
    id: number;
    title: string;
    is_pinned: boolean;
    value: string;
    description: string;
    tags: string[];
}

export interface IPromptsResponse {
    prompts: IPrompts[];
}

export interface PromptsState {
    promptList: IPromptsState;
}

export const promptsSlice = createSlice({
    name: 'prompts',
    initialState: { promptList: initialState } as PromptsState,
    reducers: {
        setPrompts: (state, action: PayloadAction<IPromptsResponse | undefined>) => {
            if (!action.payload) {
                state.promptList = {
                    prompts: [],
                };
                return;
            }

            const { prompts } = action.payload;
            state.promptList = { prompts };
        },

        addToPrompts: (state, action: PayloadAction<IPromptsResponse | undefined>) => {
            if (!action.payload) return;

            const { prompts } = action.payload;

            state.promptList.prompts = [...state.promptList.prompts, ...prompts];
        },

        togglePin: (state, action: PayloadAction<{ id: number; is_pinned: boolean }>) => {
            const { id, is_pinned } = action.payload;

            const prompt = state.promptList.prompts.find((prompt) => prompt.id === id);
            if (prompt) {
                prompt.is_pinned = is_pinned;
            }
        },

        removeById: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload;
            state.promptList.prompts = state.promptList.prompts.filter((prompt) => prompt.id !== id);
        },

        addPrompt: (state, action: PayloadAction<IPrompts | null>) => {
            if (!action.payload) return;

            state.promptList.prompts = [action.payload, ...state.promptList.prompts];
        },

        updatePrompt: (state, action: PayloadAction<IPrompts>) => {
            const updatedPrompt = action.payload;
            const index = state.promptList.prompts.findIndex((prompt) => prompt.id === updatedPrompt.id);
            if (index !== -1) {
                state.promptList.prompts[index] = updatedPrompt;
            }
        },

        cleanGallery: (state) => {
            state.promptList.prompts = [];
        },
    },
});

export const { setPrompts, addToPrompts, cleanGallery, togglePin, removeById, addPrompt, updatePrompt } =
    promptsSlice.actions;

export default promptsSlice.reducer;
