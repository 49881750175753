import { makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, shorthands } from '@fluentui/react-components';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { DeleteChatDialog } from './dialogs/DeleteChatDialog';
import { setSelectedConversation } from '../../../redux/features/conversations/conversationsSlice';
import { setIsDocumentsModalOpen } from '../../../redux/features/app/appSlice';
import { RootState } from '../../../redux/app/store';
import { useChat } from '../../../libs/hooks';

const useClasses = makeStyles({
    root: {
        display: 'contents',
        '& .fui-Button__icon ': {
            color: '#fff',
        },
        '& .fui-Button__icon:hover': {
            color: '#576DDD',
        },
    },
    menuList: {
        alignItems: 'center',
    },
    menuItem: {
        width: '100%',
        color: '#1A1D20',
        fontSize: '16px',
        '& > span': {
            display: 'flex',
            alignItems: 'center',
            ...shorthands.gap('5px'),
        },
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#576ddd',
        },
        '&:hover:active': {
            backgroundColor: 'transparent',
            color: '#576ddd',
        },
        '&:nth-of-type(2)': {
            ...shorthands.borderBottom('1px', 'solid', '#E0E5F0'),
            ...shorthands.borderTop('1px', 'solid', '#E0E5F0'),
            ...shorthands.borderRadius('0'),
        },
        '&:nth-of-type(3)': {
            ...shorthands.borderBottom('1px', 'solid', '#E0E5F0'),
            ...shorthands.borderRadius('0'),
        },
    },
    menuIcon: {
        width: '36px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)',
            ...shorthands.borderRadius('50%'),
        },
        '&:hover:active': {
            backgroundColor: 'rgba(0,0,0,0.1)',
            ...shorthands.borderRadius('50%'),
        },
    },
});

interface IListItemActionsProps {
    chatId: string;
    onEditTitleClick: () => void;
}

const MoreHorizontal = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 8C4.5 8.82843 3.82843 9.5 3 9.5C2.17157 9.5 1.5 8.82843 1.5 8C1.5 7.17157 2.17157 6.5 3 6.5C3.82843 6.5 4.5 7.17157 4.5 8ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM13 9.5C13.8284 9.5 14.5 8.82843 14.5 8C14.5 7.17157 13.8284 6.5 13 6.5C12.1716 6.5 11.5 7.17157 11.5 8C11.5 8.82843 12.1716 9.5 13 9.5Z"
            fill="#1A1D20"
        />
    </svg>
);

const RenameIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 9.579V14L6.42106 14L14 6.42185L9.57896 2L2 9.579ZM9.5789 3.41428L3 9.99321L3 13L6.00688 13L12.5858 6.42175L9.5789 3.41428Z"
            fill="#576DDD"
        />
    </svg>
);

const ChatDocumentIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.52191 2.61198C5.25026 3.12132 5.16406 3.84761 5.16406 4.66835V10.6684C5.16406 11.9553 5.40834 12.8171 5.84379 13.3531C6.26113 13.8667 6.92754 14.1684 7.9974 14.1684C9.06725 14.1684 9.73366 13.8667 10.151 13.3531C10.5864 12.8171 10.8307 11.9553 10.8307 10.6684V4.33502H11.8307V10.6684C11.8307 12.048 11.575 13.1862 10.9271 13.9837C10.2611 14.8033 9.26088 15.1684 7.9974 15.1684C6.73392 15.1684 5.73366 14.8033 5.06767 13.9837C4.41978 13.1862 4.16406 12.048 4.16406 10.6684V4.66836C4.16406 3.82243 4.24453 2.88205 4.63955 2.14139C4.84278 1.76035 5.13248 1.42564 5.53713 1.18959C5.94041 0.95434 6.42707 0.835022 6.9974 0.835022C7.56773 0.835022 8.05438 0.95434 8.45766 1.18959C8.86231 1.42564 9.15201 1.76035 9.35524 2.14139C9.75026 2.88205 9.83073 3.82243 9.83073 4.66835V10.6684C9.83073 11.4824 9.51055 11.9986 9.03713 12.2691C8.62879 12.5025 8.18312 12.5019 8.01162 12.5017C8.00665 12.5017 8.0019 12.5017 7.9974 12.5017C7.99289 12.5017 7.98814 12.5017 7.98317 12.5017C7.81167 12.5019 7.366 12.5025 6.95766 12.2691C6.48424 11.9986 6.16406 11.4824 6.16406 10.6684V4.33502H7.16406V10.6684C7.16406 11.1876 7.34389 11.3381 7.4538 11.4009C7.61694 11.4941 7.82389 11.5017 7.9974 11.5017C8.1709 11.5017 8.37785 11.4941 8.54099 11.4009C8.65091 11.3381 8.83073 11.1876 8.83073 10.6684V4.66835C8.83073 3.84761 8.74453 3.12132 8.47289 2.61198C8.34278 2.36803 8.17415 2.18191 7.95379 2.05337C7.73208 1.92404 7.42707 1.83502 6.9974 1.83502C6.56773 1.83502 6.26271 1.92404 6.041 2.05337C5.82065 2.18191 5.65201 2.36803 5.52191 2.61198Z"
            fill="#576DDD"
        />
    </svg>
);

const DeleteBtnIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.5 4.00047C6.5 4.00024 6.5 4 6 4C5.5 4 5.5 3.99975 5.5 3.99949L5.5 3.99767L5.50002 3.99458L5.50013 3.9862L5.50077 3.96088C5.50145 3.94034 5.50274 3.91251 5.50518 3.87844C5.51003 3.81046 5.5195 3.71661 5.53805 3.6053C5.57476 3.38505 5.64911 3.08375 5.80279 2.77639C5.95744 2.46708 6.19728 2.14255 6.5664 1.89647C6.93865 1.64831 7.41251 1.5 8 1.5C8.58749 1.5 9.06135 1.64831 9.4336 1.89647C9.80272 2.14255 10.0426 2.46708 10.1972 2.77639C10.3509 3.08375 10.4252 3.38505 10.4619 3.6053C10.4805 3.71661 10.49 3.81046 10.4948 3.87844C10.4973 3.91251 10.4985 3.94034 10.4992 3.96088L10.4999 3.9862L10.5 3.99458L10.5 3.99767L10.5 3.99893C10.5 3.99919 10.5 4 10 4C9.5 4 9.5 4.00024 9.5 4.00047L9.5 4.0016L9.49979 3.9942C9.4995 3.98544 9.49884 3.9703 9.49736 3.94969C9.49441 3.90829 9.48825 3.84589 9.47555 3.7697C9.44976 3.61495 9.39911 3.41625 9.30279 3.22361C9.20744 3.03292 9.07228 2.85745 8.8789 2.72853C8.68865 2.60169 8.41251 2.5 8 2.5C7.58749 2.5 7.31135 2.60169 7.1211 2.72853C6.92772 2.85745 6.79256 3.03292 6.69721 3.22361C6.60089 3.41625 6.55024 3.61495 6.52445 3.7697C6.51175 3.84589 6.50559 3.90829 6.50264 3.94969C6.50116 3.9703 6.5005 3.98544 6.50021 3.9942L6.5 4.00233L6.5 4.00047ZM6.5 4.00233C6.49999 4.00283 6.49999 4.00271 6.5 4.00233V4.00233ZM3.55248 5.5H3V4.5H13V5.5H12.4475L11.4475 14.5H4.55248L3.55248 5.5ZM4.55863 5.5L5.44752 13.5H10.5525L11.4414 5.5H4.55863Z"
            fill="#D62623"
        />
    </svg>
);

const FloppyDiskIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#576DDD" height="16" width="16" viewBox="0 0 64 64">
        <g>
            <path d="M35.2673988,6.0411h-7.9999981v10h7.9999981V6.0411z M33.3697014,14.1434002h-4.2046013V7.9387999h4.2046013V14.1434002z"></path>
            <path d="M41,47.0410995H21c-0.5527992,0-1,0.4472008-1,1c0,0.5527,0.4472008,1,1,1h20c0.5527,0,1-0.4473,1-1 C42,47.4883003,41.5527,47.0410995,41,47.0410995z"></path>
            <path d="M41,39.0410995H21c-0.5527992,0-1,0.4472008-1,1c0,0.5527,0.4472008,1,1,1h20c0.5527,0,1-0.4473,1-1 C42,39.4883003,41.5527,39.0410995,41,39.0410995z"></path>
            <path d="M12,56.0410995h38v-26H12V56.0410995z M14,32.0410995h34v22H14V32.0410995z"></path>
            <path d="M49.3811989,0.0411L49.3610992,0H7C4.7908001,0,3,1.7909,3,4v56c0,2.2092018,1.7908001,4,4,4h50 c2.2090988,0,4-1.7907982,4-4V11.6962996L49.3811989,0.0411z M39.9604988,2.0804999v17.9211006H14.0394001V2.0804999H39.9604988z M59,60c0,1.1027985-0.8972015,2-2,2H7c-1.1027999,0-2-0.8972015-2-2V4c0-1.1027999,0.8972001-2,2-2h5v20.0410995h30V2h6.5099983 L59,12.5228996V60z"></path>
        </g>
    </svg>
);

const FloppyDiskFilledIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="white" height="16" width="16" viewBox="0 0 64 64">
        <g>
            <path d="M35.2673988,6.0411h-7.9999981v10h7.9999981V6.0411z M33.3697014,14.1434002h-4.2046013V7.9387999h4.2046013V14.1434002z"></path>
            <path d="M41,47.0410995H21c-0.5527992,0-1,0.4472008-1,1c0,0.5527,0.4472008,1,1,1h20c0.5527,0,1-0.4473,1-1 C42,47.4883003,41.5527,47.0410995,41,47.0410995z"></path>
            <path d="M41,39.0410995H21c-0.5527992,0-1,0.4472008-1,1c0,0.5527,0.4472008,1,1,1h20c0.5527,0,1-0.4473,1-1 C42,39.4883003,41.5527,39.0410995,41,39.0410995z"></path>
            <path d="M12,56.0410995h38v-26H12V56.0410995z M14,32.0410995h34v22H14V32.0410995z"></path>
            <path d="M49.3811989,0.0411L49.3610992,0H7C4.7908001,0,3,1.7909,3,4v56c0,2.2092018,1.7908001,4,4,4h50 c2.2090988,0,4-1.7907982,4-4V11.6962996L49.3811989,0.0411z M39.9604988,2.0804999v17.9211006H14.0394001V2.0804999H39.9604988z M59,60c0,1.1027985-0.8972015,2-2,2H7c-1.1027999,0-2-0.8972015-2-2V4c0-1.1027999,0.8972001-2,2-2h5v20.0410995h30V2h6.5099983 L59,12.5228996V60z"></path>
        </g>
        <g stroke="white" strokeWidth="1" fill="#576DDD">
            <path d="M49.381,0.041H7C4.791,0.041,3,1.832,3,4.041v55.999c0,2.209,1.791,4,4,4h50c2.209,0,4-1.791,4-4V11.696L49.381,0.041z"></path>
            <path d="M39.96,2.08v17.921H14.039V2.08H39.96z"></path>
            <path d="M59,60.041c0,1.103-0.897,2-2,2H7c-1.103,0-2-0.897-2-2v-56c0-1.103,0.897-2,2-2h5v20.041h30V2.041 h6.51L59,12.523V60.041z"></path>
            <path d="M35.267,6.041h-8v10h8V6.041z"></path>
            <path d="M41,47.041H21c-0.553,0-1,0.447-1,1c0,0.553,0.447,1,1,1h20c0.553,0,1-0.447,1-1 C42,47.488,41.553,47.041,41,47.041z"></path>
            <path d="M41,39.041H21c-0.553,0-1,0.447-1,1c0,0.553,0.447,1,1,1h20c0.553,0,1-0.447,1-1 C42,39.488,41.553,39.041,41,39.041z"></path>
            <path d="M12,56.041h38v-26H12V56.041z"></path>
        </g>
    </svg>
);

export const ListItemActions: React.FC<IListItemActionsProps> = ({ chatId, onEditTitleClick }) => {
    const classes = useClasses();
    const chat = useChat();
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useAppDispatch();
    const { conversations } = useAppSelector((state: RootState) => state.conversations);

    const handleSaveUnsave = async () => {
        setIsLoading(true);

        if (!conversations[chatId]?.is_saved) {
            await chat.setConversationSave(chatId);
        } else {
            await chat.setConversationUnSave(chatId);
        }

        setIsLoading(false);
    };

    return (
        <div className={classes.root}>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <div className={classes.menuIcon}>
                        <MoreHorizontal />
                    </div>
                </MenuTrigger>

                <MenuPopover style={{ borderRadius: '8px' }}>
                    <MenuList className={classes.menuList}>
                        <MenuItem className={classes.menuItem} onClick={onEditTitleClick}>
                            <RenameIcon /> <span>Rename</span>
                        </MenuItem>
                        <MenuItem
                            className={classes.menuItem}
                            onClick={() => {
                                dispatch(setSelectedConversation(chatId));
                                dispatch(setIsDocumentsModalOpen(true));
                            }}
                        >
                            <ChatDocumentIcon /> <span>Chat Document</span>
                        </MenuItem>
                        <MenuItem
                            className={classes.menuItem}
                            onClick={() => {
                                void handleSaveUnsave();
                            }}
                            disabled={isLoading}
                        >
                            {!conversations[chatId]?.is_saved ? <FloppyDiskIcon /> : <FloppyDiskFilledIcon />}{' '}
                            <span>{!conversations[chatId]?.is_saved ? 'Save' : 'Unsave'}</span>
                        </MenuItem>
                        <MenuItem
                            className={classes.menuItem}
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            <DeleteBtnIcon /> <span>Delete</span>
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
            <DeleteChatDialog chatId={chatId} open={open} setOpen={setOpen} />
        </div>
    );
};
