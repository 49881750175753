import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';
import { useDispatch } from 'react-redux';
import { setIsFeaturesModalOpen } from '../redux/features/app/appSlice';

const BootstrapDialog = styled(Dialog)(() => ({
    zIndex: '10000',
    '& .MuiDialogContent-root': {
        padding: '72px',
        '@media (max-width: 744px)': {
            padding: '24px',
        },
    },
}));

const FeaturesModal = () => {
    const { isFeaturesModalOpen } = useAppSelector((state: RootState) => state.app);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setIsFeaturesModalOpen(false));
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                BackdropProps={{
                    style: {
                        backgroundColor: '#030F2B99',
                        backdropFilter: 'blur(8px)',
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={isFeaturesModalOpen}
            >
                <DialogContent>
                    <div style={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px' }}>
                        New Features & Enhancements
                    </div>
                    <div style={{ fontSize: '20px', fontWeight: '700', marginBottom: '16px' }}>
                        v2.5.0 - 26 March 2025
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: '700', marginBottom: '16px', fontStyle: 'italic' }}>
                        Improvements & Changes
                    </div>
                    <ul style={{ paddingLeft: '20px', fontSize: '16px', fontWeight: '300', marginBottom: '20px' }}>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Text</span> - Gemini 2.0 Flash as the by-default
                            model
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Text</span> - Ability to display web sources from
                            internet search made by MaIA
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Text</span> - Ability to display on demand
                            extract from documents that have been used to generate the answer
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Text</span> - Save my prompts in the Prompt
                            studio
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Text</span> - Copy paste generated code keeping
                            the formatting
                        </li>
                    </ul>
                    <div style={{ fontSize: '20px', fontWeight: '700', marginBottom: '16px' }}>
                        v2.4.0 - 06 March 2025
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: '700', marginBottom: '16px', fontStyle: 'italic' }}>
                        Improvements & Changes
                    </div>
                    <ul style={{ paddingLeft: '20px', fontSize: '16px', fontWeight: '300', marginBottom: '20px' }}>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Text</span> - Addition of 2 new models: Claude
                            Sonnet 3.5 V2 from Anthropic and Mistral Large 24.11 from Mistral
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Text</span> - Substitution of Gemini 1.5 Pro by
                            Gemini 2.0 Flash
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Text</span> - Addition of new prompts in the
                            studio
                        </li>
                    </ul>
                    <div style={{ fontSize: '20px', fontWeight: '700', marginBottom: '16px' }}>
                        v2.3.1 - 29 January 2025
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: '700', marginBottom: '16px', fontStyle: 'italic' }}>
                        Improvements & Changes
                    </div>
                    <ul style={{ paddingLeft: '20px', fontSize: '16px', fontWeight: '300', marginBottom: '20px' }}>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA</span> - The Code of Use is displayed and must be
                            accepted every 30 days
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Text</span> - Prompt studio: Library of expertly
                            crafted prompts to get better answers faster.
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Text</span> - Copy with formatting
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Image</span> - Enhanced prompt: add some
                            auto-suggested elements to your prompt to generate better image
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Image</span> - Visualisation of images on mobile
                            is now faster
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Image</span> -Adding a countdown in the gallery
                            to visualize when images will be deleted
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Image</span> - Be able to navigate through images
                            with keyboard
                        </li>
                    </ul>
                    <div style={{ fontSize: '20px', fontWeight: '700', marginBottom: '16px' }}>
                        v2.2.2 - 10 December 2024
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: '700', marginBottom: '16px', fontStyle: 'italic' }}>
                        Improvements & Changes
                    </div>
                    <ul style={{ paddingLeft: '20px', fontSize: '16px', fontWeight: '300', marginBottom: '20px' }}>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Image</span> - Change thumbnail size to 1024px
                            ratio
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Text</span> - Change “Add Document” button style
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA text & Image</span> - Info/Error message
                            displayed when a query failed
                        </li>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA text & Image</span> - Updated Code of use and
                            privacy policies added
                        </li>
                    </ul>
                    <div style={{ fontSize: '16px', fontWeight: '700', marginBottom: '16px', fontStyle: 'italic' }}>
                        Bug fix
                    </div>
                    <ul style={{ paddingLeft: '20px', fontSize: '16px', fontWeight: '300', marginBottom: '40px' }}>
                        <li>
                            <span style={{ fontStyle: 'italic' }}>MaIA Image</span> - Bugfix on Image not generated due
                            to connexion aborted
                        </li>
                    </ul>
                    <div style={{ fontSize: '20px', fontWeight: '700', marginBottom: '16px' }}>
                        v2.2.1 - 28 November 2024
                    </div>
                    <ul style={{ paddingLeft: '20px', fontSize: '16px', fontWeight: '300', marginBottom: '40px' }}>
                        <li>Fix to improve the success rate of image generation requests</li>
                    </ul>
                    <div style={{ fontSize: '20px', fontWeight: '700', marginBottom: '16px' }}>
                        v2.2.0 - 21 November 2024
                    </div>
                    <ul style={{ paddingLeft: '20px', fontSize: '16px', fontWeight: '300', marginBottom: '40px' }}>
                        <li>Change MaIA welcome message</li>
                        <li>Setting OpenAI GPT-4o as default model for text-to-text</li>
                        <li>Error message improvements for user when context is too large for the model</li>
                        <li>Update font size, colors and margin on chat window</li>
                        <li>Retrieval of user&apos;s country for reporting purposes</li>
                    </ul>
                    <div style={{ fontSize: '20px', fontWeight: '700', marginBottom: '16px' }}>
                        v2.1.0 - 15 October 2024
                    </div>
                    <ul style={{ paddingLeft: '20px', fontSize: '16px', fontWeight: '300', marginBottom: '40px' }}>
                        <li>MaIA can now generate images.</li>
                        <li>Ability to drag and drop documents.</li>
                        <li>Gemini model updated.</li>
                    </ul>
                    <div style={{ fontSize: '20px', fontWeight: '700', marginBottom: '16px' }}>
                        v2.0 - 26 September 2024
                    </div>
                    <ul style={{ paddingLeft: '20px', fontSize: '16px', fontWeight: '300', marginBottom: '40px' }}>
                        <li>Access to internet for up-to-date answers.</li>
                        <li>Model chooser: Benefit from more performant technologies according to your tasks.</li>
                        <li>Query a webpage directly by copying the link.</li>
                        <li>MaIA answers evaluation via feedbacks.</li>
                        <li>Better understanding of complex and large documents</li>
                    </ul>
                    <div
                        onClick={() => {
                            handleClose();
                        }}
                        style={{
                            borderRadius: '28px',
                            background: '#030F2B',
                            color: '#fff',
                            padding: '16px 48px',
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        RETURN TO MAIA
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
};

export default FeaturesModal;
